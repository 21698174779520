import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        setMenu: {}
    },
    getters: {},
    mutations: {
        SETSETMENU(state, value) {
            state.setMenu = value;
        }
    },
    actions: {},
    modules: {}
});
