import request from "../request.js";

function loginApi(params) {
    return new Promise((resolve, reject) => {
        const deviceId = localStorage.getItem("deviceId");
        request
            .post("api/auth/login", {
                ...params,
                deviceId
            })
            .then((res) => {
                if (res.success && res.data) {
                    localStorage.setItem("token", res.data.token);
                    resolve(true);
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function checkTokenApi() {
    return request.get("api/auth/check/token", {});
}

function checkAndLoginApi() {
    return new Promise(async (resolve, reject) => {
        const token = localStorage.getItem("token");
        if (!token) {
            loginApi()
                .then(() => {
                    resolve(true);
                })
                .catch((err) => {
                    reject(err);
                });
            return;
        }
        const res = await checkTokenApi();
        if (res.success && res.data) {
            resolve(true);
            return;
        }
        loginApi()
            .then(() => {
                resolve(true);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export { loginApi, checkTokenApi, checkAndLoginApi };
