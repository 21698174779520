import axios from "axios";

axios.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => response,
    (error) => Promise.resolve(error.response)
);

axios.defaults.baseURL = "/";

function checkStatus(response) {
    // console.log(response);

    if (response.status === 200 || response.status === 304) {
        return response.data;
    }
    return {
        data: {
            code: -404,
            message: response.statusText,
            data: response.statusText
        }
    };
}

function checkCode(res) {
    if (res.code !== 200) {
        // alert(res.data.message);
        console.log(res.message);
    }
    return res;
}

export default {
    post(url, data) {
        const Authorization = localStorage.getItem("token") || "";
        if (process.env.NODE_ENV === "development") {
            url = `/avantshort/${url}`;
        }
        return axios({
            method: "post",
            url,
            data,
            timeout: 30000,
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/json; charset=UTF-8",
                Authorization
                // "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
        })
            .then(checkStatus)
            .then(checkCode);
    },
    get(url, params) {
        const Authorization = localStorage.getItem("token") || "";
        if (process.env.NODE_ENV === "development") {
            url = `/avantshort/${url}`;
        }
        return axios({
            method: "get",
            url,
            params,
            timeout: 30000,
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/json; charset=UTF-8",
                Authorization
            }
        })
            .then(checkStatus)
            .then(checkCode);
    }
};
