<template>
    <header>
        <div class="name">
            <slot>
                <div class="back" @click="back"></div>
                <span>{{ name }}</span>
            </slot>
        </div>
        <slot name="right" v-if="rightShow">
            <div class="nav" :class="{ open: visiable }" @click.stop="handleClick"></div>
        </slot>
        <div class="menu_warp" @click.self="visiable = false" v-if="visiable">
            <div class="menu">
                <div class="menuItem" v-for="(m, i) in menuArr" :key="i">
                    <div class="menuLabel" :class="[m.id, m.secMenu && current === m.id ? 'secMenuOpen' : '']" @click="menuClick(m)">{{ m.name }}</div>
                    <div class="secMenu" v-if="m.secMenu">
                        {{ m.secMenuName }}
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: "MHeader",
    props: {
        name: {},
        rightShow: {
            default: true
        }
    },
    data() {
        return {
            visiable: false,
            menuArr: [
                // { id: "SUL", name: "Sign Up / Log in" },
                { id: "FL", name: "Follow List", path: "/followList" },
                { id: "CU", name: "Contact Us", secMenu: true, secMenuName: "Liuliuaisupport@gmail.com" },
                { id: "TOS", name: "Terms Of Service", path: "/msg" },
                { id: "PP", name: "Privacy Policy", path: "/msg" },
                { id: "RP", name: "Refund Policy", path: "/msg" },
                { id: "CMP", name: "Content Monitoring Policy", path: "/msg" }
            ],
            current: ""
        };
    },
    methods: {
        handleClick() {
            this.visiable = !this.visiable;
        },
        menuClick(m) {
            if (this.current === m.id) {
                this.current = "";
            } else {
                this.current = m.id;
            }
            if (m.path) {
                this.$router.push({ path: m.path, query: { key: m.name } });
            }
        },
        back() {
            this.visiable = false;
            if (this.$listeners.back) {
                this.$listeners.back();
            } else {
                this.$router.back();
            }
        }
    },
    watch: {
        visiable(v) {
            if (!v) {
                this.current = "";
                document.body.style.overflow = "auto";
            } else {
                document.body.style.overflow = "hidden";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
header {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    height: 44px;
    width: 100%;
    align-items: center;
    color: #fff;
    padding: 0 15px;
    background: #000;
    z-index: 99;

    div {
        display: flex;
        align-items: center;
    }
    .name {
        font-size: 18px;
        z-index: 99;
        width: 80%;
        display: flex;

        .back {
            width: 22px;
            height: 22px;
            background: url("./images/back.png") 0 0 / cover no-repeat;
            margin-right: 4px;
        }
        span {
            flex: 1;
            min-width: 0;
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .nav {
        width: 24px;
        height: 24px;
        background: url("./images/nav.png") 0 0 / cover no-repeat;
        z-index: 99;
        transition: all 0.1s;
        &.open {
            background: url("./images/close.png") 0 0 / 24px no-repeat;
        }
    }
    .menu_warp {
        display: block;
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 98;
        top: 0;
        left: 0;
    }
    .menu {
        display: block;
        width: 100%;
        background: #202028;
        font-size: 13px;
        padding: 10px 0;
        padding-top: 45px;
        .menuItem {
            display: block;
            .menuLabel {
                height: 50px;
                padding: 0 16px;
                padding-left: 49px;
                justify-content: space-between;
                &::after {
                    content: "";
                    display: block;
                    width: 18px;
                    height: 18px;
                    background: url("./images/go.png") 50% 50% / cover no-repeat;
                }
                &.secMenuOpen {
                    &::after {
                        background: url("./images/open.png") 50% 50% / cover no-repeat;
                    }
                }
            }
            .menuLabel.secMenuOpen + .secMenu {
                height: 50px;
            }
            .secMenu {
                overflow: hidden;
                height: 0px;
                background: #00000033;
                transition: all 0.1s;
                padding-left: 51px;
                color: #d45252;
            }
        }
        .SUL {
            background: url("./images/SUL.png") 16px 50% / 16px no-repeat;
        }
        .FL {
            background: url("./images/FL.png") 16px 50% / 17px no-repeat;
        }
        .CU {
            background: url("./images/CU.png") 16px 50% / 20px no-repeat;
        }
        .TOS,
        .RP,
        .CMP {
            background: url("./images/TOS.png") 16px 50% / 20px no-repeat;
        }
        .PP {
            background: url("./images/PP.png") 16px 50% / 20px no-repeat;
        }
    }
}
</style>
