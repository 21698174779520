export default {
    data() {
        return {
            TermsOfService: {
                title: "Terms of Service",
                content: `
					<h2>Acceptance</h2>
					Liuliuai provides services and products on this Website, subject to the following terms and conditions. You should read the Terms of Service (“TOS”) carefully before using this Website and the Services and Products available to you on this website. By accessing or using the Website, the Services, and the Products (collectively, “Services”), you agree to be bound by these terms and conditions. If you do not agree with or accept any of these terms, you should cease accessing or using the Services immediately.
	
					<h2>Modification</h2>
					Liuliuai reserves the right to update these terms and conditions, and the information, the products, and the services mentioned at any time, at its sole discretion, without prior notice. The most recent version of the TOS can be reviewed by clicking on the “Terms of Service” hypertext link located at the bottom of our Website pages.
	
					<h2>Eligibility</h2>
					You must be at least 16 years of age to use the Platform. By agreeing to these Terms, you represent and warrant to us that: <br /> 
					1) you are at least 16 years of age;<br /> 
					2) you have not previously been suspended or removed from the Service; <br /> 
					3) your registration and your use of the Platform is in compliance with all applicable laws and regulations.If you are using the Service on behalf of an entity, organization, or company, you represent and warrant that you have the authority to bind that organization to these Terms and you agree to be bound by these Terms on behalf of that organization.
	
					<h2>License to Users</h2>
					1) Subject to your full compliance with the Terms and to the extent we are lawfully able to grant such rights, Liuliuai grants you a non-exclusive, non-sublicensable and non-transferable license to the Intellectual Property solely for the purposes of accessing Works, using the Platform, and for other purposes expressly stated herein.<br /> 
					2) For the avoidance of doubt, the Terms do not transfer from us to you any of our, or any third party’s intellectual property rights, and all right, title and interest in and to such property will remain vested with the applicable owner.
	
					<h2>Registration and Accounts</h2>
					1) To use certain features of the Platform, you are required to register for a Liuliuai account (an “Account”). You agree that all information that you submit during the Account signup process, or additional information you submit to your Account profile at any time thereafter, shall be accurate and truthful, and you shall keep the same up to date.<br /> 
					2) Your Account is unique and personal to you, and you are responsible for maintaining the security of, and restricting access to, your account and password, and you agree to accept responsibility for all purchases and other activities that occur under your Account.<br /> 
					3) You have the ability to delete your Account, and you should understand that upon deletion of your Account, you will lose the right to access or use all or part of the Platform.<br /> 
					4) We reserve the right to disable, suspend, or terminate your Account, at any time, for any reason in our discretion, especially when you fail to comply with any provision of these Terms.
	
					<h2>Content Policy</h2>
					1) Our content policy, (“Content Policy”), sets out, among other things, the terms on Liuliuai’s uses of Content which writers and publishers upload to the Platform. You must review and agree to the Content Policy prior to uploading any Works on the Platform.<br /> 
					2) For the avoidance of doubt, all rights not expressly granted to Liuliuai herein shall be reserved to you and Liuliuai is not claiming any ownership in and to your Content.
	
					<h2>Restricted User Conduct</h2>
					1) Except and solely to the extent such a restriction is impermissible under applicable law, you may not: (a) reproduce, distribute, publicly display, or publicly perform the Service; (b) make modifications to the Service; or (c) interfere with or circumvent any feature of the Service, including any security or access control mechanism. If you are prohibited under applicable law from using the Service, you may not use it.<br /> 
					2) You will not alter or modify the Platform in any way nor use other technology or means to access the Platform except for the means authorized by Liuliuai, including, without limitation, using no bots, spiders, or other automated means of accessing the Platform.
	
					<h2>Payment Terms</h2>
					1) Access to the Platform, or to certain features of the Service, may require you to pay fees. Before you pay any fees, you will have an opportunity to review and accept the fees that you will be charged. All fees are in U.S. dollars and are non-refundable.<br /> 
					2) Liuliuai reserves the right to determine pricing for the Service. Liuliuai will make reasonable efforts to keep pricing information published on the website up to date. We encourage you to check our website periodically for current pricing information.<br /> 
					3) Liuliuai will charge the payment method you specify at the time of purchase. You authorize Liuliuai to charge to that payment method all sums for orders that you make and any level of Service you select as described in these Terms or published by the Company. If you pay any fees with a credit card, Liuliuai may seek pre-authorization of your credit card account prior to your purchase to verify that the credit card is valid and has the necessary funds or credit available to cover your purchase. Payment processing services are provided by the third-party service through which the purchase is made (e.g., Apple In-App Purchases, Google Play, PayPay).<br /> 
					4) All purchases may be subject to taxes and other fees, including, without limitation, foreign exchange fees or differences in prices based on location (e.g. exchange rates). We may calculate taxes payable by you based on the information that you provide us via your Account or at the time or purchase.
	
					<h2>Disclaimers</h2>
					1) The Platform is made available “as is”, “as available”, and “with all faults” for the stated purposes herein. Use of the Platform is entirely at your own risk and you should use your best judgment and exercise caution while using the Platform.<br /> 
					2) Liuliuai makes no guarantee that your use of the Platform, and all other features or functionalities associated with the Platform, or delivery or display of the Platform, will be available, uninterrupted, interference free, or error free, or be free from any viruses, worms, or other security intrusions. You understand and agree that you use the Platform, and use, access, download, or otherwise obtain materials or content through the Platform and any associated sites or Platforms, at your own discretion and risk, and that you are solely responsible for any damage to your property (including your computer system or mobile device used in connection with the Platform), or the loss of data that results from the use if the Platform or the download or use of that material or content.<br /> 
					3) Liuliuai does not guarantee the availability, delivery, performance, pricing, or punctuality of any Works or other Intellectual Property appearing on the Platform. Without limitation, this includes making no guarantee that any future Episodes of a Work are posted by a writer or publisher in a timely manner, that Works will remain available on the Platform, or the pricing of Works or Services.<br /> 
					4) Liuliuai assumes no responsibility for the deletion or failure to store any Comments or other information submitted by you to the Platform, and we expressly do not promise to store or keep any Comments that you have submitted to the Platform. You are solely responsible for saving backup copies of any Comments that you upload to the Platform.<br /> 
					5) You understand and agree that Liuliuai is not liable for any failure of performance due to any cause beyond its control, including, without limitation, acts of God, fire, explosion, vandalism, terrorism, weather disturbances, national emergencies, riots, wars, labor difficulties, supplier failures, shortages, breaches, action or request by any government, suspension of existing service in compliance with state or federal law, rule, or regulations.
	
					<h2>Limitation of Liability</h2>
					1) Notwithstanding anything to the contrary contained herein, Liuliuai shall not be liable to you for any direct, indirect, special, incidental, consequential, exemplary, extra-contractual, or punitive damages of any kind whatsoever, which are in any way related to the Platform or these Terms, regardless of legal theory (including, without limitation, contract, tort, personal injury, property damage, negligence, warranty, or strict liability), whether or not Liuliuai has been advised of the possibility or probability of such damages, and even if the remedies otherwise available fail of their essential purposes. If you are dissatisfied with the Platform, or with any of these Terms, or feel Liuliuai has breached these Terms, your sole and exclusive remedy is to discontinue using the Platform. This limitation of liability is part of the basis of the bargain between us.<br /> 
					2) Notwithstanding anything to the contrary herein, if any part of this limitation of liability is found to be invalid or unenforceable for any reason, Liuliuai’s agreement liability arising out of breach of this Terms and/or your use of the Platform will not exceed, in the agreement amount for all claims, five hundred dollars ($500.00 USD).
	
					<h2>Indemnification</h2>
					1) You are responsible for your use of the Platform, and you will defend and indemnify Liuliuai and its officers, directors, employees, consultants, affiliates, subsidiaries and agents (together, the “Liuliuai Entities”) from and against every claim, liability, damage, loss, and expense, including reasonable attorneys' fees and costs, arising out of or in any way connected with: (a) your access to, use of, or alleged use of, the Platform; (b) your violation of any portion of these Terms, any representation, warranty, or agreement referenced in these Terms, or any applicable law or regulation; (c) your violation of any third-party right, including any intellectual property right or publicity, confidentiality, other property, or privacy right; or (d) any dispute or issue between you and any third party. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you (without limiting your indemnification obligations with respect to that matter), and in that case, you agree to cooperate with our defense of that claim.<br /> 
					2) Liuliuai reserves the right to take over the exclusive defense of Claims for which Liuliuai is entitled to indemnification, and in such event, you shall provide prompt and reasonable cooperation to Liuliuai at your own expense.
	
					<h2>Promotions</h2>
					In addition to these Terms, a Promotion made available by Liuliuai through the Platform, or otherwise may have specific rules that are different from these Terms. By participating in a Promotion, you agree to and will become subject to those additional terms and conditions, which will be provided to you when a Promotion is offered. Liuliuai urges you to review all rules before you participate in any Promotion. The rules of a specific Promotion will take priority over these Terms in the event of any conflict of the language with a given Promotion.
	
					<h2>Infringement of Intellectual Property Rights</h2>
					1) Liuliuai respects the intellectual property rights of others and desires to offer a Platform that contains no material that violates the intellectual property rights of others. If you believe in good faith that your intellectual property rights have been infringed through use on the Platform, you may notify us by email at: Liuliuaisupport@gmail.com with “Notice of Infringement” in the subject line, which contains: An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright (or other intellectual property) interest; A description of the copyrighted work (or other intellectual property interest) that you claim has been infringed; A description specifying the location on the Platform of the material that you claim is infringing; Your email address and your mailing address and/or telephone number; A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright (or other intellectual property) owner, its agent, or the law; and A statement by you made under penalty of perjury, that the information in your notice is accurate and that you are the copyright (or other intellectual property) owner or authorized to act on the copyright (or other intellectual property) owner’s behalf. If you believe that a notice of intellectual property infringement has been improperly submitted against you, you may submit a “Counter-Notice of Infringement” to Liuliuai, which contains: Your physical or electronic signature; Identification of the material removed or to which access has been disabled; A statement under penalty of perjury that you have a good faith belief that removal or disablement of the material was a mistake or that the material was misidentified; and Your full name, your email address, your mailing address, and a statement that you consent to the jurisdiction of the court (a) in the judicial district where your address is located.<br /> 
					2) Any notice or counter-notice you submit pursuant to the foregoing must be truthful and must be submitted under penalty of perjury. A false notice or counter-notice may give rise to personal liability. You may therefore want to seek the advice of legal counsel before submitting a notice or a counter-notice. Notwithstanding anything to the contrary stated herein, please note that whether or not Liuliuai disables access to or removes any materials pursuant to the foregoing, Liuliuai may disclose your identity to any third party who has alleged a violation of intellectual property rights here under, or supplied us with a counter-notice, as well as disclose the contents of any notice of counter-notice. You acknowledge and agree that any disclosures by us pursuant to the foregoing shall not violate any of your rights, including, without limitation, any rights of privacy that you may have.
	
					<h2>Notices</h2>
					All legal notices pursuant to the Terms shall be in writing and shall be given by email to Liuliuai at: Liuliuaisupport@gmail.com. By using the Platform, you agree that any notice due under the Terms that Liuliuai sends you electronically will satisfy any legal communication or notification. You agree to keep all of your contact information contained in your Account up to date, and we are not responsible in the event you fail to keep your Account or contact information up to date.
				`
            },
            PrivacyPolicy: {
                title: "Privacy Policy",
                content: `
					<h2></h2>
					Last Updated: [October 16, 2023]<br />
					This Privacy Policy applies to the Liuliuai services (“Liuliuai” or “Platform”)namely the Liuliuai application (“we” or “us”).<br />
					We recognize that your privacy is very important, and we are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, share and otherwise process your personal information in connection with our services. This Privacy Policy applies globally, with jurisdiction-specific provisions detailed in the Supplemental Terms – Jurisdiction-Specific section. If you do not agree with this Privacy Policy, please do not use Liuliuai.

					<h2>THIS PRIVACY POLICY IS IMPORTANT, AND WE HOPE THAT YOU WILL TAKE THE TIME TO READ IT CAREFULLY BEFORE YOU USE LIULIUAI.</h2>

					<h3>1.What Information We Collect</h3>
					We may collect the following information about you:<br />
					<h4>1) Information You Provide</h4>
					Information you provide when contacting us. To access customer service on Liuliuai, you may provide us with your [contact (email address), and text/picture/video information relating to your request]. Your user ID and relevant problem description are needed to better resolve your problem.We may also collect information in correspondence you send to us, including when you contact us for complaints, support, or feedback.<br />
					<h4>2) Automatically Collected Information</h4>
					When you access and use Liuliuai, we will automatically collect your information:<br />
					Usage Information. We will collect information regarding your interaction with Liuliuai, including [your records on clicking, following, searching, the content you save to “My List”, browsing, and transaction]. We collect such information to analyze your appetite and infer your interests for the purpose of personalizing content recommendations. For example, we may collect [your “Check-in” history, following records, records of push notification activation, and the length of time you watch a video, to calculate the rewards you earn during the coin reward activity. We may also display the information on transaction and consumption history, and rewarded coin records] we collected on related product pages in the Platform].<br />
					Cookies. Cookies are text files placed on your device to collect standard Internet log information and visitor behavior information. We may automatically collect information from you, through cookies or similar technologies, including (e.g., web beacons, flash cookies, etc.) (“Cookies”), to automatically collect information, measure and analyze how you use the Platform, including which pages you view most often and how you interact with content, enhance your experience using the Platform, improve the Platform, provide you with advertising, and measure the effectiveness of advertisements and other content. We and our partners also use Cookies to promote the Platform on other platforms and websites. Cookies enable the Platform to provide certain features and functionality. Web beacons are very small images or small pieces of data embedded in images, also known as “pixel tags” or “clear GIFs,” that can recognize Cookies, the time and date a page is viewed, a description of the page w the pixel tag is placed, and similar information from your computer or device. 

					<h3>2.How We Use Your Information</h3>
					We use the information we collect for the following purposes:
					<h4>1) Service Provision</h4>
					To conclude and enforce the agreement with you.<br />
					To deliver, provide, process, and maintain our services provided to you.<br />
					To create your account and profile.
					<h4>2) Communications</h4>
					To communicate with you about your use of Liuliuai, product announcements, and software updates, as well as respond to your requests for assistance.
					<h4>3) Improvement of Your Experience</h4>
					To personalize the content you see when you use Liuliuai. For example, show you content that we think will interest you.<br />
					To ensure content is presented in the most effective manner for you and your device.
					<h4>4) Legal Compliance</h4>
					To comply with legal or regulatory obligations, including but not limited to responding to lawsuits, damages or investigations, within or outside of your jurisdiction or in any instance of mergers, acquisitions, sales and asset transfers, insolvency proceedings, corporate restructuring, winding up or liquidation.

					<h3>3.How We Share Your Information</h3>
					We do not share your personal information except as explicitly provided in this Privacy Policy. We share your personal information in one or multiple of the following circumstances:
					<h4>1) Sharing with Your Consent</h4>
					We may share information with third parties with your consent or at your direction.
					<h4>2) Sharing in the Event of Merger, Sale or Other Business Transactions</h4>
					We may transfer your personal information to another entity in the context of a transaction including a merger, financing, acquisition, restructuring, division, bankruptcy, transfer of assets or similar transaction.<br />
					We will notify you in this regard and demand the new holder of the information abide by and perform any and all contents of this Privacy Policy.
					<h4>3) Sharing for Legal Reasons</h4>
					We may share your personal information with law enforcement agencies, government regulators, courts and other public authorities as required by law or regulators, to fulfill our legal obligations.<br />
					We may also share your personal information with such an authority if it is reasonably necessary to:<br />
					comply with a legal obligation, process or request;<br />
					enforce our Terms of Service and other agreements, policies, and standards, including investigation of any potential violation thereof;<br />
					exercise, establish or defend legal rights;<br />
					detect, prevent or otherwise address security, fraud or technical issues;<br />
					protect the rights, property or safety of us, our users, a third party or the public as required or permitted by law.

					<h3>4. How We Store and Protect Your Information</h3>
					<h4>1) How We Store Your Information</h4>
					Location.<br />
					Presently, our server for storing your personal information related to Liuliuai is located in USA. You understand and agree that, under the conditions and terms of this Privacy Policy, we may transmit and store your personal information to/in such server upon compliance with applicable laws and regulations. Though the standard for the protection of personal information may vary in the location of our server and the judicial jurisdiction area of your place, we will do our best to protect your personal information with stricter standards and make the biggest effort possible to prevent the disclosure of your information.<br />
					As stated in this Privacy Policy, we may share your personal information with third parties, who may be located outside USA. We will not transmit your personal information until we have complied with the applicable laws and regulations regarding cross-border transmission.<br />
					Retention Period.<br />
					We retain your personal information until the termination of your account and otherwise as long as necessary for the purposes described above. We also retain personal information as long as necessary to comply with legal obligations, enforce our agreements and resolve disputes.<br />
					The retention periods are different depending on different criteria, such as [the type of information, the purposes for which we use the information and mandatory retention periods under applicable law].<br />
					After the retention period, we either delete or anonymize your personal information, unless otherwise required by applicable laws and regulations.
					<h4>2) How We Protect Your Information</h4>
					We will take appropriate technical and management measures to help protect and secure your personal information, such as [encryption and desensitization]. We maintain these technical and organizational measures and will amend them from time to time to improve the overall security of our systems. Although we are committed to protecting your personal information, no electronic data transmission or storage of information can be completely secure. Therefore, we cannot guarantee and you should not expect that your personal information will always remain secure. If you do not agree to these risks and conditions, you must discontinue your use of Liuliuai.<br />
					Technical Measures<br />
					We adopt encryption technology such as security agreement on the transmission layer to prevent the transmission from being sniffed, bugged or intercepted; adopt security storage measures such as classified and layered disposal of data; adopt strict data access control system, adopt multiple identity certification technology, monitor data processing to avoid illegal access and unauthorized use of data; monitor and audit the entire lifetime of data to prevent unauthorized access, disclosure, use, alternation, man-made or accidental damage or loss of personal information.]<br />
					Management Measures<br />
					We establish special department for personal information protection and formulated relevant internal control management system to minimize employee authorization such as the standard for secure use of business data and standard management system of data cooperation; organize employees to participate in relevant security protection training on a regular basis and take other practicable security organization and management measures, such as locking the storage area of personal information and restricting employees’ access to the storage area.]

					<h3>5.What Are Your Rights and Choices</h3>
					You have all the rights under applicable laws and regulations concerning your personal information, including but not limited to the following:<br />
					the right to access your personal information;<br />
					the right to rectify/alter your personal information;<br />
					the right to delete your personal information;<br />
					the right to unsubscribe/cancel notifications;<br />
					the right to select personalized content;<br />
					the right to limit or refuse processing of personal information.<br />
					To submit a request to exercise the above rights or solve your trouble in exercising such rights, you may contact us via the contact information provided in and we shall help you upon verification of your identity within the proper period or the period prescribed by the law of your jurisdiction. You may appeal any decision we have made about your request by following the instructions in the communication you receive from us notifying you of our decision. <br />
					You can exercise part of your rights on relevant product pages in Liuliuai:<br />
					The right to access your personal information.<br />
					Access profile information. You may access your personal information including [your user ID, nickname, profile photo] by clicking “Profile” in the application.<br />
					Usage information. You may access part of your usage information at any time through the related product page, including [the content you save to “My List” (by clicking “My List”), watch history (by clicking “Continue Watching”)].<br />
					The right to copy your personal information. On the Profile page, you may also copy your [user ID].<br />
					The right to delete your personal information.<br />
					Delete usage information. You may delete part of your usage information at any time through the related product page. For example, [you may delete your browsing records by clicking “Profile” - “Watch history”. You may also delete the content you save to “My List” by clicking “My List”].

					<h3>6. How do we handle information about minors</h3>
					Liuliuai is not directed at children under the age of 16 years old or the minimum age otherwise defined in Supplemental Terms – Jurisdiction Specific under the applicable laws or regulations of your jurisdiction (“Minimum Age”). Therefore, we do not knowingly collect personal information of anyone under the Minimum Age. If you are under the Minimum Age, please do not use Liuliuai and do not send your personal information to us. If you believe we may have any information about children under the Minimum Age, you may contact us via the contact information provided in. If we learn that we have collected personal information from children under the Minimum Age, we will promptly take steps to delete such information and terminate the associated account.<br />
					If you have reached the Minimum Age but are under the age of majority as defined under the applicable laws and regulations, you may only use Liuliuai through your parent or legal guardian’s representation. You shall read this Privacy Policy under the guardianship and guidance of your parent or legal guardians, and submit relevant personal information after the consent of your parent or legal guardian to this Privacy Policy. You and your parent or legal guardian by accept this Privacy Policy.

					<h3>7. Privacy Policy Updates</h3>
					In response to changing legal, technical or business development, we may update or revise this Privacy Policy from time to time. When we update this Privacy Policy, we will post the updated version and change the “Last Updated” date at the top of this Privacy Policy. We also will take appropriate measures to inform you in advance of significant changes that we believe affect your privacy rights in one or more manners, [such as announcements, push notifications, pop-ups and email]. W your consent is required by applicable privacy laws, we will obtain your consent to changes before the revised Privacy Policy applies to you. We advise you to check this Privacy Policy every time you use our services to ensure you are aware of the updated version.

					<h3>8.How to Contact Us</h3>
					If you have any question, comment, complaint, request or advice regarding this Privacy Policy or any other privacy-related matters, you may contact us at email address: Liuliuaisupport@gmail.com.<br />
					Upon receiving your query or request, we will, upon verification of your identity, reply to you within the period prescribed by the applicable law. We will endeavor to deal with your request as soon as possible. This is without prejudice to your right to make a complaint with a relevant data protection authority, w applicable.

				`
            },
            ContactUs: {
                title: "Contact Us",
                content: "Liuliuaisupport@gmail.com"
            },
            RefundPolicy: {
                title: "Refund Policy",
                content: `
					<h2>1. Introduction</h2>
					Thank you for choosing LIULIUAI! We are committed to providing top-notch short dramas and an exceptional entertainment experience. Before subscribing, please review our refund policy to understand your rights and responsibilities.
					<h2>2. Non-Refundable Subscriptions</h2>
					All subscriptions on LIULIUAI are final and non-refundable. This includes:
					<h4>•	Unlock Full Episodes</h4>
					<h4>•	Weekly Subscription</h4>
					<h4>•	Monthly Subscription</h4>
					<h4>•	Yearly Subscription</h4>
					Once a subscription is purchased, refunds will not be provided for any reason, except as outlined under "Exceptions to the Refund Policy."
					<h2>3. Exceptions to the Refund Policy</h2>
					Although we maintain a no-refund policy, exceptions may be made under the following circumstances:<br />
					<strong>•	Technical Issues</strong>: If platform-related technical problems prevent you from accessing your subscription, you must notify us within 7 days of the issue. Depending on the situation, we may offer a refund or extend your subscription.<br />
					<strong>•	Unauthorized Transactions</strong>: If you identify an unauthorized charge, notify us immediately. Following an investigation, if the charge is confirmed to be fraudulent, a refund will be issued.
					<h2>4. Refund Request Process</h2>
					If you believe you qualify for a refund, follow these steps:
					<h4>1)	Submit a Request:</h4>
					o	Email us at Liuliuaisupport@gmail.com with the following details:<br />
						Your account information (e.g., user ID, email address).<br />
						Subscription details (e.g., plan type, order ID).<br />
						A detailed description of the issue and supporting evidence (e.g., screenshots).
					<h4>2)	Review Process:</h4>
					o	Our team will review your request within 3 business days and respond via email.
					<h4>3)	Refund Processing:</h4>
					o	If your refund request is approved, the refund will be issued to your original payment method. Please allow 3-5 business days for the funds to appear.
					<h2>5. Cancelling a Subscription</h2>
					Cancelling your subscription will not result in a refund for any unused portion of your subscription. However, you will continue to have access to all content until the end of your current billing cycle.
					<h2>6. Contact Us</h2>
					For questions about this policy or assistance with your subscription, please reach out to our support team at Liuliuaisupport@gmail.com. We are here to help!
					<h2>7. Policy Agreement</h2>
					By subscribing to LIULIUAI, you agree to abide by the terms outlined in this refund policy. Thank you for your understanding and for choosing LIULIUAI as your entertainment platform!
				`
            },
            ContentMonitoringPolicy: {
                title: "Content Monitoring Policy",
                content: `
					<h2>1. Objective</h2>
					Our goal is to foster a secure and inclusive environment by prohibiting adult content on the platform. These guidelines define unacceptable content and detail enforcement procedures to maintain compliance.
					<h2>2. Applicability</h2>
					These guidelines are mandatory for all users, creators, and contributors interacting with our platform. They cover all forms of content, including text, images, videos, audio, and hyperlinks.
					<h2>3. Restricted Content Categories</h2>
					The following are strictly prohibited:<br />
					1)	<strong>Explicit Material</strong>: Any depiction of sexual acts, nudity, or intimate content designed for sexual stimulation.<br />
					2)	<strong>Solicitation of Sexual Services</strong>: Content encouraging or requesting sexual services in any form.<br />
					3)	<strong>Suggestive Content</strong>: Subtle or overt references, visuals, or language suggesting sexual activity.<br />
					4)	<strong>Inappropriate Discussions</strong>: Comments or conversations that are sexually explicit, offensive, or aimed at harassment.<br />
					5)	<strong>Adult Website Links</strong>: Posting or sharing URLs leading to adult-themed websites.
					<h2>4. Enforcement Strategies</h2>
					We use a combination of tools and processes to enforce these guidelines:<br />
					1)	<strong>Automated Detection</strong>: Advanced algorithms to identify and restrict problematic content.<br />
					2)	<strong>Content Moderation Team</strong>: Manual reviews conducted by our trained moderation staff.<br />
					3)	<strong>Community Reporting</strong>: Users can flag content they suspect violates these guidelines for review.
					<h2>5. Penalties for Non-Compliance</h2>
					Violations will result in the following actions:<br />
					1)	<strong>Immediate Content Removal</strong>: Inappropriate material will be taken down as soon as it is identified.<br />
					2)	<strong>User Notification</strong>: A warning will be issued for the first infraction.<br />
					3)	<strong>Temporary Account Suspension</strong>: Serious or repeated offenses may lead to account suspension.<br />
					4)	<strong>Permanent Account Ban</strong>: Severe or continuous breaches can result in permanent account termination.
					<h2>6. Appeals Process</h2>
					If users believe a moderation decision was incorrect, they can submit an appeal to LIULIUAI. Appeals will be reviewed carefully by our moderation team, and decisions will be communicated promptly.
					<h2>7. Policy Revisions</h2>
					We reserve the right to modify these guidelines as needed. Updates will be announced via the platform to ensure all users are informed.
					<h2>8. Support and Inquiries</h2>
					For questions, concerns, or appeals, contact us at Liuliuaisupport@gmail.com.
				`
            }
        };
    },
    methods: {}
};
