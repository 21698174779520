<template>
    <bottom-popup ref="popup" class="wPay-popup" v-bind="$attrs">
        <div class="wPay">
            <div class="close" @click="close"></div>
            <div class="title">
                <h1>Set up your credit or debit card</h1>
                <div class="card">
                    <span class="visa"></span>
                    <span class="masterCard"></span>
                </div>
            </div>

            <div class="form" ref="form">
                <div class="webstripeInput card-number-element" id="card-number-element"></div>
                <div class="webstripeInput half card-expiry-element" id="card-expiry-element"></div>
                <div class="webstripeInput half r card-cvc-element" id="card-cvc-element"></div>
                <!-- <div><input class="cardNum" type="text" placeholder="Card Number" @input="formatBankCard" v-model="form.carNum" maxlength="19" /></div>
				<div class="half"><input type="text" placeholder="MM/YY" /></div>
				<div class="half r"><input type="text" placeholder="CVC" /></div> -->
                <div class="half my"><input type="text" v-model="form.firstName" placeholder="First Name" /></div>
                <div class="half r my"><input type="text" v-model="form.lastName" placeholder="Last Name" /></div>
                <div class="my"><input class="Email" type="text" v-model="form.email" placeholder="Email" /></div>
            </div>
            <div class="intr">
                Your membership will automatically renew every <span>{{ setMenu.day || "--" }}</span> days for USD <span>{{ setMenu.priceShow || "--" }}</span
                >, which will be deducted from your paymentmethod. You may cancel your membership at any time toavoid future charges.
            </div>
            <div class="agree" ref="agree"><input type="checkbox" v-model="form.agree" /> I Agree</div>
            <div class="payMent" @click="PayNow">Pay Now</div>
        </div>
    </bottom-popup>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import BottomPopup from "./BottomPopup.vue";
import { mapState } from "vuex";
export default {
    name: "wPay",
    components: {
        BottomPopup
    },
    computed: {
        ...mapState({
            setMenu: (state) => state.setMenu
        })
    },
    data() {
        return {
            form: {
                firstName: "",
                lastName: "",
                email: ""
            },
            stripe: null,
            errorMessage: "",
            clientSecret: null,
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null
        };
    },
    methods: {
        async open() {
            this.$refs.popup.openPopup();

            const keyRes = await this.getPK();
            const key = keyRes.data;

            // 初始化 Stripe 并加载公共密钥
            this.stripe = await loadStripe(key);

            // 初始化 Stripe Elements
            const elements = this.stripe.elements();

            const style = {
                base: {
                    color: "#fff", // 文字颜色
                    fontSize: "14px", // 字体大小
                    fontSmoothing: "antialiased", // 平滑字体
                    height: "20px"
                },
                invalid: {
                    color: "#fa755a", // 错误状态的文字颜色
                    iconColor: "#fa755a" // 错误状态的图标颜色
                }
            };

            // 单独创建卡号、有效期、CVC 控件
            const cardNumber = (this.cardNumber = elements.create("cardNumber", { style, placeholder: "Card Number" }));
            const cardExpiry = (this.cardExpiry = elements.create("cardExpiry", { style, placeholder: "MM/YY" }));
            const cardCvc = (this.cardCvc = elements.create("cardCvc", { style, placeholder: "CVC" }));

            // 将这些控件挂载到页面的相应 DOM 元素上
            cardNumber.mount("#card-number-element");
            cardExpiry.mount("#card-expiry-element");
            cardCvc.mount("#card-cvc-element");

            // 获取 client secret（需要后端支持）
            // await this.fetchClientSecret();
        },
        close() {
            this.$refs.popup.closePopup();
        },
        formatBankCard(e) {
            var rawValue = e.target.value;
            var formattedValue = rawValue.replace(/\D/g, "").replace(/(\d{4})(?=\d)/g, "$1 ");
            this.form.carNum = formattedValue;
        },
        async PayNow() {
            this.formValidate().then(async () => {
                const { error, paymentMethod } = await this.stripe.createPaymentMethod({
                    type: "card",
                    card: this.cardNumber,
                    billing_details: {
                        name: `${this.form.firstName} ${this.form.lastName}`, // 可选，填入客户名称
                        email: this.form.email // 可选，填入客户邮箱
                    }
                });
                if (error) {
                    // 处理错误信息
                    alert(error.message);
                } else {
                    // 成功创建了 PaymentMethod
                    // console.log("PaymentMethod ID:", paymentMethod.id);
                    // 在这里发送 paymentMethod.id 到你的服务器，进行下一步支付处理
                    this.$http
                        .post("api/stripe/create-subscription", {
                            couponId: "",
                            paymentMethodId: paymentMethod.id,
                            subscribeConfigId: this.setMenu.id,
                            email: this.form.email
                        })
                        .then(async (res) => {
                            console.log(res);
                            if (res.success && res.data) {
                                const clientSecret = res.data.clientSecret; // 从后端获取的 clientSecret
                                const result = await this.stripe.confirmCardPayment(clientSecret, {
                                    payment_method: {
                                        card: this.cardNumber, // 卡片元素
                                        billing_details: {
                                            name: `${this.form.firstName} ${this.form.lastName}`, // 客户名称
                                            email: this.form.email // 客户邮箱
                                        }
                                    }
                                });

                                if (result.error) {
                                    // 支付失败
                                    console.error("Payment failed:", result.error.message);
                                    this.$Toast({
                                        message: "Payment failed. Please try again.",
                                        duration: 3000
                                    });
                                } else if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
                                    // 支付成功
                                    console.log("Payment succeeded:", result.paymentIntent);
                                    this.$Toast({
                                        message: "Subscription payment succeeded!",
                                        duration: 3000
                                    });
                                }
                            } else {
                                this.$Toast({
                                    message: "Submission failed",
                                    duration: 3000
                                });
                            }
                        })
                        .catch(() => {
                            this.$Toast({
                                message: "Submission failed",
                                duration: 3000
                            });
                        });
                    // 例如，发送到后端的支付 API
                    // fetch("api/charge", {
                    //     method: "POST",
                    //     headers: {
                    //         "Content-Type": "application/json"
                    //     },
                    //     body: JSON.stringify({
                    //         paymentMethodId: paymentMethod.id,
                    //         amount: 5000 // 以分为单位
                    //     })
                    // }).then((response) => {
                    //     if (response.ok) {
                    //         console.log("Payment successful!");
                    //     } else {
                    //         console.log("Payment failed.");
                    //     }
                    // });
                }

                // console.log("go");
                // this.$router.replace({ name: "play", params: { pay: true } });
                // this.$router.back();
            });
        },
        formValidate() {
            return new Promise((r) => {
                let bol2 = false;
                let arr = [];
                this.$refs.form.childNodes.forEach((c) => {
                    c.className = c.className.replace(" no", "");
                    if (c.className.indexOf("my") >= 0) {
                        const input = c.childNodes[0];
                        if (input.value) {
                            if (input.className === "Email" && input.value.indexOf("@") < 0) {
                                c.className = c.className + " no";
                                arr.push(false);
                            }
                            arr.push(true);
                        } else {
                            if (c.className.indexOf("no") < 0) {
                                c.className = c.className + " no";
                            }
                            arr.push(false);
                        }
                    } else {
                        if (c.className.indexOf("card-number-element") >= 0 && this.cardNumber._empty) {
                            c.className = c.className + " no";
                            arr.push(false);
                        } else {
                            arr.push(true);
                        }
                        if (c.className.indexOf("card-expiry-element") >= 0 && this.cardExpiry._empty) {
                            c.className = c.className + " no";
                            arr.push(false);
                        } else {
                            arr.push(true);
                        }
                        if (c.className.indexOf("card-cvc-element") >= 0 && this.cardCvc._empty) {
                            c.className = c.className + " no";
                            arr.push(false);
                        } else {
                            arr.push(true);
                        }
                    }
                });
                if (!this.form.agree) {
                    this.$refs.agree.className += " no";
                    bol2 = false;
                } else {
                    bol2 = true;
                    this.$refs.agree.className = this.$refs.agree.className.replace(" no", "");
                }
                if (arr.every((a) => a) && bol2) {
                    r(true);
                }
            });
        },
        getPK() {
            return this.$http.post("api/stripe/get-pk", {});
        }
    }
};
</script>

<style lang="scss" scoped>
.wPay-popup {
    ::v-deep .popup-content {
        width: 536px;
        padding: 0;
    }
    .wPay {
        position: relative;
        background: #1d0f0f;
        padding-bottom: 32px;
        .close {
            position: absolute;
            right: 24px;
            top: 24px;
            background: url("../../../images/close.png") 0 0 / 24px no-repeat;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
        .title {
            height: 115px;
            background: url("../../../images/payBg2.png") 0 0 / cover no-repeat;
            padding: 0 16px;
            overflow: hidden;
            h1 {
                margin: 0;
                font-size: 21px;
                font-weight: 500;
                height: 29px;
                line-height: 29px;
                margin-top: 42px;
                text-align: center;
            }
            .card {
                margin-top: 10px;
                line-height: 0;
                span {
                    display: inline-block;
                    width: 36px;
                    height: 22px;
                    border-radius: 4px;
                    & + span {
                        margin-left: 9px;
                    }
                    &.visa {
                        background: url("../../../images/visa.png") 0 0 / cover no-repeat;
                    }
                    &.masterCard {
                        background: url("../../../images/masterCard.png") 0 0 / cover no-repeat;
                    }
                }
            }
        }

        .form {
            font-size: 0;
            padding: 20px 24px 0;
            & > div {
                display: inline-block;
                width: 100%;
                border: 1px solid #ffffff33;
                border-radius: 4px;
                input {
                    width: 100%;
                    border: none;
                    background: #ffffff1a;
                    height: 46px;

                    outline: none;
                    color: #fff;
                    padding: 13px 16px;
                    font-size: 14px;
                }
                & + div {
                    margin-top: 28px;
                }
                &.half {
                    width: calc((100% - 15px) / 2);
                }
                &.half.r {
                    margin-left: 15px;
                }
            }
        }
        .intr {
            margin-top: 28px;
            padding: 0 24px;
            font-size: 11px;
            font-weight: 400;
            line-height: 15.4px;
            text-align: left;
            span {
                color: #ecb671;
            }
        }
        .agree {
            margin: 25px 24px;
            font-size: 12px;
            font-weight: 500;
            line-height: 16.8px;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            input {
                margin-right: 9px;
            }
        }

        .payMent {
            background: #d45252;
            height: 44px;
            border-radius: 8px;
            text-align: center;
            line-height: 44px;
            font-size: 16px;
            margin: 0 24px;
            margin-top: 21px;
        }
        .no {
            position: relative;
            input {
                border-color: #d45252 !important;
            }
            &::after {
                content: "Please check";
                display: block;
                color: #d45252;
                font-size: 12px;
                position: absolute;
                left: 0;
                bottom: -19px;
            }
        }
    }
}
</style>
<style lang="scss">
.webstripeInput {
    width: 100%;
    border: 1px solid #ffffff33;
    background: #ffffff1a;
    height: 46px;
    border-radius: 4px;
    outline: none;
    color: #fff;
    padding: 13px 16px;
    font-size: 14px;
    & > div {
        height: 100%;
        & > iframe {
            height: 100% !important;
        }
    }
}
</style>
