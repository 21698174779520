import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/mobile/home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: home,
        meta: {
            keepAlive: true
        }
    },
    {
        path: "/followList",
        name: "followList",
        component: () => import(/* webpackChunkName: "about" */ "../views/mobile/followList.vue")
    },
    {
        path: "/play",
        name: "play",
        component: () => import(/* webpackChunkName: "about" */ "../views/mobile/play.vue")
    },
    {
        path: "/pay",
        name: "pay",
        component: () => import(/* webpackChunkName: "about" */ "../views/mobile/pay.vue")
    },
    {
        path: "/msg",
        name: "msg",
        component: () => import(/* webpackChunkName: "about" */ "../views/mobile/msg.vue")
    },
    {
        path: "/web",
        name: "web",
        component: () => import(/* webpackChunkName: "about" */ "../views/web/index.vue"),
        children: [
            {
                path: "/",
                redirect: "/web/index"
            },
            {
                path: "index",
                name: "index",
                component: () => import(/* webpackChunkName: "about" */ "../views/web/home.vue")
            },
            {
                path: "browse",
                name: "browse",
                component: () => import(/* webpackChunkName: "about" */ "../views/web/browse.vue")
            },
            {
                path: "myList",
                name: "myList",
                component: () => import(/* webpackChunkName: "about" */ "../views/web/myList.vue")
            },
            {
                path: "play",
                name: "wplay",
                component: () => import(/* webpackChunkName: "about" */ "../views/web/play.vue")
            },
            {
                path: "msg",
                name: "wmsg",
                component: () => import(/* webpackChunkName: "about" */ "../views/web/msg.vue")
            }
        ]
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});

function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isHarmonyOS = /harmonyos/i.test(userAgent);

    // 判断设备是否为手机
    if (isHarmonyOS) {
        return /mobile/i.test(userAgent) || window.innerWidth <= 768; // 可根据需要调整宽度阈值
    }

    // 检测其他常见移动设备
    return /android|iphone|ipad|ipod|windows phone|blackberry|iemobile|mobile/i.test(userAgent);
}

router.beforeEach((to, from, next) => {
    const name = to.name;
    if (to.path.indexOf("web") < 0) {
        if (!isMobile()) {
            switch (name) {
                case "play":
                case "msg":
                    next(`/web${to.fullPath}`);
                    break;
                case "followList":
                    next("/web/myList");
                    break;
                default:
                    next("/web/index");
                    break;
            }
            return;
        }
    } else {
        if (isMobile()) {
            switch (name) {
                case "wplay":
                case "wmsg":
                    next(`${to.fullPath.replace(/\/web/, "")}`);
                    break;
                case "myList":
                    next("/followList");
                    break;
                default:
                    next("/");
                    break;
            }
            return;
        }
    }

    next();
});

export default router;
